<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Sales") }}</label>
      <span>/</span>
      <label style="cursor:pointer" @click="goBack">
        <span v-if="subscribeFrom">
            {{ $t(subscribeFrom) }}
        </span>

        <span v-else>
          {{ $t('Subscribes') }}
        </span>
        
      </label>
      <span>/</span>
      <label active>{{ $route.params.id ? $t("Edit") : $t("Add") }}</label>
    </div>

    <v-toolbar-title class="mt-3">{{
      $route.params.id ? $t("EditSubscribe") : $t("AddNewSubscribe")
    }}</v-toolbar-title>

    <v-tabs class="mt-2" v-model="activeTab" background-color="primary" dark>
      <v-tab>{{ $t("Basic customer data") }}</v-tab>
      <v-tab>{{ $t("Measurement data") }}</v-tab>
      <v-tab>{{ $t("Financial data") }}</v-tab>
      <v-tab>{{ $t("Personal desires") }}</v-tab>
      <v-tab>{{ $t("Contact information") }}</v-tab>
      <v-tab>{{ $t("Subscription data") }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.SubscriberName`),
                  }"
                >
                  <label>{{ $t("SubscriberName") }}</label>

                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="SubscriberName"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('SubscriberName')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.customer.name"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.SubscriberName`)"
                >
                  {{ errors.first(`addEditValidation.SubscriberName`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.Email`),
                  }"
                >
                  <label>{{ $t("Email") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="Email"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Email')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.customer.email"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.Email`)"
                >
                  {{ errors.first(`addEditValidation.Email`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.Mobile`),
                  }"
                >
                  <label>{{ $t("Mobile") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="Mobile"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('Mobile')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.customer.mobile"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.Mobile`)"
                >
                  {{ errors.first(`addEditValidation.Mobile`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.birth_date`),
                  }"
                >
                  <label>{{ $t("BirthDate") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.customer.birth_date"
                    type="date"
                    v-validate="''"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('BirthDate')"
                    class="d-block my-2"
                    name="birth_date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.birth_date`)"
                >
                  {{ errors.first(`addEditValidation.birth_date`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>

      <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.height`),
                  }"
                >
                  <label>{{ $t("Height") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="height"
                    data-vv-scope="addEditValidation"
                    v-validate="'numeric'"
                    :data-vv-as="$t('Height')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.measurement.height"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.height`)"
                >
                  {{ errors.first(`addEditValidation.height`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.weight`),
                  }"
                >
                  <label>{{ $t("Weight") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="weight"
                    data-vv-scope="addEditValidation"
                    v-validate="'numeric'"
                    :data-vv-as="$t('Weight')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.measurement.weight"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.weight`)"
                >
                  {{ errors.first(`addEditValidation.weight`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.muscle`),
                  }"
                >
                  <label>{{ $t("Muscle") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="muscle"
                    data-vv-scope="addEditValidation"
                    v-validate="'numeric'"
                    :data-vv-as="$t('Muscle')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.measurement.muscle"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.muscle`)"
                >
                  {{ errors.first(`addEditValidation.muscle`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.liquids`),
                  }"
                >
                  <label>{{ $t("Liquids") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="liquids"
                    data-vv-scope="addEditValidation"
                    v-validate="'numeric'"
                    :data-vv-as="$t('Liquids')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.measurement.fluid"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.liquids`)"
                >
                  {{ errors.first(`addEditValidation.liquids`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.target`),
                  }"
                >
                  <label>{{ $t("Target") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="target"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Target')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.measurement.target"
                    item-value="id"
                    item-text="name"
                    :items="targetList"
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.target`)"
                >
                  {{ errors.first(`addEditValidation.target`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>

      <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.bank`),
                  }"
                >
                  <label>{{ $t("Bank") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="bank"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Bank')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.bank.bank_name_id"
                    item-value="id"
                    item-text="name"
                    :items="bankList"
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.bank`)"
                >
                  {{ errors.first(`addEditValidation.bank`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(
                      `addEditValidation.ReferenceNumber`
                    ),
                  }"
                >
                  <label>{{ $t("ReferenceNumber") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="ReferenceNumber"
                    data-vv-scope="addEditValidation"
                    v-validate="'numeric'"
                    :data-vv-as="$t('ReferenceNumber')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.bank.number_money_transfer"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.ReferenceNumber`)"
                >
                  {{ errors.first(`addEditValidation.ReferenceNumber`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.change_date`),
                  }"
                >
                  <label>{{ $t("ChangeDate") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="formData.bank.transfer_date"
                    type="date"
                    class="mt-2"
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.change_date`)"
                >
                  {{ errors.first(`addEditValidation.change_date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.PriceChange`),
                  }"
                >
                  <label>{{ $t("PriceChange") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="PriceChange"
                    data-vv-scope="addEditValidation"
                    v-validate="'numeric'"
                    :data-vv-as="$t('PriceChange')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.bank.amount"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.PriceChange`)"
                >
                  {{ errors.first(`addEditValidation.PriceChange`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(
                      `addEditValidation.MainIngredients`
                    ),
                  }"
                >
                  <label>{{ $t("MainIngredients") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="MainIngredients"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('MainIngredients')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.personal_desires.ingredients"
                    item-value="id"
                    item-text="name"
                    :items="mainIngredientList"
                    multiple
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.MainIngredients`)"
                >
                  {{ errors.first(`addEditValidation.MainIngredients`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(
                      `addEditValidation.NotMainIngredients`
                    ),
                  }"
                >
                  <label>{{ $t("NotMainIngredients") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="NotMainIngredients"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('NotMainIngredients')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.personal_desires.not_ingredients"
                    item-value="id"
                    item-text="name"
                    :items="notMainIngredientList"
                    multiple
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.NotMainIngredients`)"
                >
                  {{ errors.first(`addEditValidation.NotMainIngredients`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.recipe_group`),
                  }"
                >
                  <label>{{ $t("Preferences from feature") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="recipe_group"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Preferences from features')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.personal_desires.recipies"
                    multiple
                    item-value="id"
                    item-text="name"
                    :items="recipeGroupList"
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.recipe_group`)"
                >
                  {{ errors.first(`addEditValidation.recipe_group`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.carb`),
                  }"
                >
                  <label>{{ $t("Carb") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="carb"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Carb')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.personal_desires.carbohydrates"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.carb`)"
                >
                  {{ errors.first(`addEditValidation.carb`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.protin`),
                  }"
                >
                  <label>{{ $t("Protin") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="protin"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Protin')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.personal_desires.protein"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.protin`)"
                >
                  {{ errors.first(`addEditValidation.protin`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.notes`),
                  }"
                >
                  <label>{{ $t("Notes") }}</label>
                  <v-textarea
                    class="d-block my-2"
                    type="text"
                    name="notes"
                    rows="4"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Notes')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.personal_desires.notes"
                  ></v-textarea>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.notes`)"
                >
                  {{ errors.first(`addEditValidation.notes`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.City`),
                  }"
                >
                  <label>{{ $t("City") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="City"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('City')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.delivery.city_id"
                    item-value="id"
                    item-text="name"
                    :items="cityList"
                    @change="getNeighborhoodList(formData.delivery.city_id)"
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.City`)"
                >
                  {{ errors.first(`addEditValidation.City`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.Neighborhood`),
                  }"
                >
                  <label>{{ $t("Neighborhood") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="Neighborhood"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Neighborhood')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.delivery.branch_id"
                    item-value="id"
                    item-text="name"
                    :items="neighborhoodList"
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.Neighborhood`)"
                >
                  {{ errors.first(`addEditValidation.Neighborhood`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.Period`),
                  }"
                >
                  <label>{{ $t("Period") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="Period"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Period')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.delivery.period"
                    item-value="id"
                    item-text="name"
                    :items="periodList"
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.Period`)"
                >
                  {{ errors.first(`addEditValidation.Period`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.HomeNumber`),
                  }"
                >
                  <label>{{ $t("HomeNumber") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="HomeNumber"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('HomeNumber')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.delivery.home_number"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.HomeNumber`)"
                >
                  {{ errors.first(`addEditValidation.HomeNumber`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.company`),
                  }"
                >
                  <label>{{ $t("Company") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="company"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Company')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.delivery.company_id"
                    item-value="id"
                    item-text="name"
                    :items="companyList"
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.company`)"
                >
                  {{ errors.first(`addEditValidation.company`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.people_group`),
                  }"
                >
                  <label>{{ $t("PeopleGroup") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="people_group"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('PeopleGroup')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.delivery.group_name_id"
                    item-value="id"
                    item-text="name"
                    :items="peopleGroupList"
                    clearable
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.people_group`)"
                >
                  {{ errors.first(`addEditValidation.people_group`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.address`),
                  }"
                >
                  <label>{{ $t("Address") }}</label>
                  <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="address"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Address')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.delivery.address"
                  ></v-text-field>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.address`)"
                >
                  {{ errors.first(`addEditValidation.address`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.notes2`),
                  }"
                >
                  <label>{{ $t("Notes") }}</label>
                  <v-textarea
                    class="d-block my-2"
                    type="text"
                    name="notes2"
                    rows="4"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Notes')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.delivery.notes"
                  ></v-textarea>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.notes2`)"
                >
                  {{ errors.first(`addEditValidation.notes2`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="6">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.Package`),
                  }"
                >
                  <label>{{ $t("Package") }}</label>
                  <v-autocomplete
                    class="d-block my-2"
                    name="Package"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('Package')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.subscribe.package_id"
                    item-value="id"
                    item-text="name"
                    :items="packageList"
                    clearable
                    @change="changePackage(formData.subscribe.package_id)"
                  ></v-autocomplete>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.Package`)"
                >
                  {{ errors.first(`addEditValidation.Package`) }}
                </div>
              </v-col>

              <template v-if="packageDetails">
                <v-col class="py-0" :cols="6">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.price`),
                    }"
                  >
                    <label>{{ $t("Price") }}</label>
                    <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="price"
                      data-vv-scope="addEditValidation"
                      v-validate="''"
                      disabled
                      :data-vv-as="$t('Price')"
                      hide-details
                      dense
                      outlined
                      v-model="packageDetails.price"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.price`)"
                  >
                    {{ errors.first(`addEditValidation.price`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="6" v-if="!$route.params.id">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.Package`),
                    }"
                  >
                    <label>{{ $t("Days") }}</label>
                    <v-autocomplete
                      class="d-block my-2"
                      name="Package"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('Days')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.day_status"
                      item-value="id"
                      item-text="name"
                      :items="dayStatusList"
                      clearable
                    ></v-autocomplete>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.Package`)"
                  >
                    {{ errors.first(`addEditValidation.Package`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="6" v-if="!$route.params.id">
                  <div
                    :class="{
                      'has-error': errors.has(
                        `addEditValidation.number_of_days`
                      ),
                    }"
                  >
                    <label>{{ $t("NumberOfDaysPackage") }}</label>
                    <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="number_of_days"
                      data-vv-scope="addEditValidation"
                      v-validate="'numeric'"
                      :disabled="formData.subscribe.day_status == 1"
                      :data-vv-as="$t('NumberOfDaysPackage')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.number_of_days"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.number_of_days`)"
                  >
                    {{ errors.first(`addEditValidation.number_of_days`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="6">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.discount`),
                    }"
                  >
                    <label>{{ $t("Discount") }}</label>
                    <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="discount"
                      data-vv-scope="addEditValidation"
                      v-validate="'required|numeric'"
                      :data-vv-as="$t('Discount')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.discount"
                      @keyup="changeDiscount"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.discount`)"
                  >
                    {{ errors.first(`addEditValidation.discount`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="6">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.start_date`),
                    }"
                  >
                    <label>{{ $t("StartDate") }}</label>
                    <el-date-picker
                      style="width: 100%"
                      :disabled="$route.params.id"
                      v-model="formData.subscribe.start_date"
                      type="date"
                      class="mt-2"
                    >
                    </el-date-picker>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.start_date`)"
                  >
                    {{ errors.first(`addEditValidation.start_date`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="6">
                  <div
                    :class="{
                      'has-error': errors.has(
                        `addEditValidation.subscribe_status`
                      ),
                    }"
                  >
                    <label>{{ $t("SubscribeStatus") }}</label>
                    <v-autocomplete
                      class="d-block my-2"
                      name="subscribe_status"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('SubscribeStatus')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.subscribe_status"
                      item-value="id"
                      item-text="name"
                      :items="subscribeStatusList"
                      clearable
                    ></v-autocomplete>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.subscribe_status`)"
                  >
                    {{ errors.first(`addEditValidation.subscribe_status`) }}
                  </div>
                </v-col>

                <!-- <v-col class="py-0" :cols="6" v-if="$route.params.id">
                  <div
                    :class="{
                      'has-error': errors.has(
                        `addEditValidation.renewSubscribe`
                      ),
                    }"
                  >
                    <label>{{ $t("RenewSubscribe") }}</label>
                    <v-autocomplete
                      class="d-block my-2"
                      name="renewSubscribe"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('RenewSubscribe')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.renewSubscribe"
                      item-value="id"
                      item-text="name"
                      :items="renewSubscribeList"
                      @change="
                        changeRenewSubscribe(formData.subscribe.renewSubscribe)
                      "
                      clearable
                    ></v-autocomplete>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.renewSubscribe`)"
                  >
                    {{ errors.first(`addEditValidation.renewSubscribe`) }}
                  </div>
                </v-col>

                <v-col
                  class="py-0"
                  :cols="6"
                  v-if="
                    packageDetails &&
                    $route.params.id &&
                    formData.subscribe.renewSubscribe == 1
                  "
                >
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.renew_date`),
                    }"
                  >
                    <label>{{ $t("RenewDate") }}</label>
                    <el-date-picker
                      style="width: 100%"
                      v-model="formData.subscribe.renew_date"
                      type="date"
                      class="mt-2"
                      name="renew_date"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('RenewDayNumbers')"
                      hide-details
                      dense
                      outlined
                    >
                    </el-date-picker>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.renew_date`)"
                  >
                    {{ errors.first(`addEditValidation.renew_date`) }}
                  </div>
                </v-col>

                <v-col
                  class="py-0"
                  :cols="6"
                  v-if="
                    packageDetails &&
                    $route.params.id &&
                    formData.subscribe.renewSubscribe == 1
                  "
                >
                  <div
                    :class="{
                      'has-error': errors.has(
                        `addEditValidation.renew_day_numbers`
                      ),
                    }"
                  >
                    <label>{{ $t("RenewDayNumbers") }}</label>
                    <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="renew_day_numbers"
                      data-vv-scope="addEditValidation"
                      v-validate="'required|numeric'"
                      :data-vv-as="$t('RenewDayNumbers')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.renew_day_numbers"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.renew_day_numbers`)"
                  >
                    {{ errors.first(`addEditValidation.renew_day_numbers`) }}
                  </div>
                </v-col> -->

                <v-col
                  class="py-0"
                  :cols="3"
                  v-if="packageDetails && $route.params.id"
                >
                  <div>
                    <label>{{ $t("SubscriptionsDays") }}</label>
                    <v-alert color="blue">
                      {{ formData.subscribe.count_subscriptions_day }}
                    </v-alert>
                  </div>
                </v-col>

                <v-col
                  class="py-0"
                  :cols="2"
                  v-if="packageDetails && $route.params.id"
                >
                  <div>
                    <label>{{ $t("RemindDays") }}</label>
                      <v-text-field 
                      v-if="formData.subscribe.is_remind_days" 
                       class="d-block my-2"
                      type="text"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.count_of_remind_day" color="orange">
                      </v-text-field>
                    <v-alert v-else color="orange">
                      {{ formData.subscribe.remind_days }}
                    </v-alert>
                  </div>
                </v-col>
                <v-col
                  class="py-0"
                  :cols="1"
                  v-if="packageDetails && $route.params.id"
                >
                    <div>
                    <label>{{ $t("IsEdit") }}</label>
                    <v-checkbox  
                    @change="changeRemindDays(formData.subscribe.is_remind_days)"
                    v-model="formData.subscribe.is_remind_days">
                    </v-checkbox>
                  </div>
                </v-col>

                <v-col class="py-0" :cols="6">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.total`),
                    }"
                  >
                    <label>{{ $t("Total") }}</label>
                    <v-text-field
                      class="d-block my-2"
                      type="text"
                      name="total"
                      data-vv-scope="addEditValidation"
                      v-validate="''"
                      disabled
                      :data-vv-as="$t('Total')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.total"
                    ></v-text-field>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.total`)"
                  >
                    {{ errors.first(`addEditValidation.total`) }}
                  </div>
                </v-col>

                <v-col class="py-0" :cols="6">
                  <div
                    :class="{
                      'has-error': errors.has(`addEditValidation.days`),
                    }"
                  >
                    <label>{{ $t("DaysSelected") }}</label>
                    <v-autocomplete
                      class="d-block my-2"
                      name="days"
                      data-vv-scope="addEditValidation"
                      v-validate="'required'"
                      :data-vv-as="$t('DaysSelected')"
                      hide-details
                      dense
                      outlined
                      v-model="formData.subscribe.days"
                      item-value="id"
                      item-text="name"
                      :items="dayList"
                      @change="dayListChanged"
                      multiple
                      clearable
                    ></v-autocomplete>
                  </div>

                  <div
                    class="help-block"
                    v-if="errors.has(`addEditValidation.days`)"
                  >
                    {{ errors.first(`addEditValidation.days`) }}
                  </div>
                </v-col>

                <v-container fluid>
                  <div>
                    <el-button
                      @click="addNew"
                      type="primary float-right mb-5"
                      >{{ $t("Add") }}</el-button
                    >
                  </div>

                  <table id="pacakgeFeaturesTable">
                    <tr>
                      <th>#</th>
                      <th>اسم المجموعة</th>
                      <th>الكمية</th>
                      <th>العمليات</th>
                    </tr>

                    <tr
                      v-for="(l, lIndex) in formData.subscribe.group_subscribe"
                      :key="lIndex"
                    >
                      <td>
                        {{ lIndex + 1 }}
                      </td>
                      <td>
                        <div
                          :class="{
                            'has-error': errors.has(`addEditValidation.group${lIndex}`),
                          }"
                        >
                          <label>{{ $t("Group") }}</label>
                          <v-autocomplete
                            class="d-block my-2"
                            :name="`group${lIndex}`"
                            data-vv-scope="addEditValidation"
                            v-validate="'required'"
                            :data-vv-as="$t('Group')"
                            hide-details
                            dense
                            outlined
                            v-model="l.id"
                            item-value="id"
                            item-text="name"
                            :items="listGroup"
                            clearable
                          ></v-autocomplete>
                        </div>

                        <div
                          class="help-block"
                          v-if="errors.has(`addEditValidation.group${lIndex}`)"
                        >
                          {{ errors.first(`addEditValidation.group${lIndex}`) }}
                        </div>
                      </td>
                      <td>
                        <div
                          :class="{
                            'has-error': errors.has(
                              `addEditValidation.quantity${lIndex}`
                            ),
                          }"
                        >
                          <label>{{ $t("Quantity") }}</label>
                          <v-text-field
                            class="d-block my-2"
                            :name="`quantity${lIndex}`"
                            data-vv-scope="addEditValidation"
                            v-validate="'required|numeric'"
                            :data-vv-as="$t('Quantity')"
                            hide-details
                            dense
                            outlined
                            v-model="l.quantity"
                          ></v-text-field>
                        </div>

                        <div
                          class="help-block"
                          v-if="errors.has(`addEditValidation.quantity${lIndex}`)"
                        >
                          {{ errors.first(`addEditValidation.quantity${lIndex}`) }}
                        </div>
                      </td>
                      <td>
                        <v-icon
                          class="my-0 mr-2 ml-2"
                          dense
                          @click="removeRow(lIndex)"
                          color="danger"
                          >mdi-delete</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-container>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>

    <v-container fluid>
      <v-row class="float-left">
        <v-btn
          v-if="activeTab == 5 && user  && user.permissions
          &&
        ( user.permissions.includes('store_subscribe')
        ||  user.permissions.includes('update_subscribe')
        ) 

          
          "
          class="mr-2 ml-5"
          color="success"
          dark
          outlined
          small
          @click="save"
        >
          {{ $t("Save") }}
        </v-btn>
        <v-btn
          v-else-if="activeTab != 5 "
          class="mr-2 ml-5"
          color="primary"
          dark
          outlined
          small
          @click="next"
        >
          {{ $t("Next") }}
        </v-btn>
      </v-row>
    </v-container>

      <BlockUI v-if ="loadClicked" :message="msg">
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </BlockUI>


  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "subscribeAddEdit",
  data() {
    return {
               user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      subscribeFrom: localStorage.getItem('addSubscribeFrom') || 'all',
      defaultDays: [],
       loadClicked: false,
       msg: 'برجاء الانتظار لحين اكتمال العملية',
      activeTab: 0,
      formData: {
        customer: {
          name: null,
          mobile: null,
          email: null,
          birth_date: null,
        },
        measurement: {
          height: null,
          weight: null,
          muscle: null,
          fluid: null,
          target: null,
        },
        bank: {
          bank_name_id: null,
          transfer_date: null,
          number_money_transfer: null,
          amount: null,
        },
        personal_desires: {
          ingredients: [],
          not_ingredients: [],
          recipies: [],
          protein: [],
          carbohydrates: [],
          notes: "",
        },
        delivery: {
          city_id: null,
          branch_id: null,
          home_address: null,
          period: null,
          home_number: null,
          company_id: null,
          group_name_id: null,
          address: null,
          notes: null,
        },
        subscribe: {
          package_id: null,
          day_status: 1,
          discount: null,
          days: [],
          start_date: null,
          total: null,
          number_of_days: null,
          // renew_date: null,
          // renew_day_numbers: null,
          remind_days: null,
          subscribe_status: null,
          // renewSubscribe: 2,
          group_subscribe: [], // id, quentity
          is_remind_days: false,
          count_of_remind_day: 0,
        },
      },
      packageList: [],
      cityList: [],
      neighborhoodList: [],
      PeopleGroup: [],
      dayStatusList: [
        {
          id: 1,
          name: this.$t("Automatic"),
        },
        {
          id: 2,
          name: this.$t("Manual"),
        },
      ],
      targetList: [
        {
          id: 1,
          name: "تضخيم",
        },
        {
          id: 2,
          name: "تنقيص وزن",
        },
        {
          id: 3,
          name: "تنشيف",
        },
      ],
      subscribeStatusList: [
        {
          id: 1,
          name: this.$t("Paid"),
        },
        {
          id: 2,
          name: this.$t("NotPaid"),
        },
      ],
      renewSubscribeList: [
        {
          id: 1,
          name: this.$t("Yes"),
        },
        {
          id: 2,
          name: this.$t("No"),
        },
      ],
      periodList: [
        {
          id: 1,
          name: "صباحي",
        },
        {
          id: 2,
          name: "مسائي",
        },
      ],
      bankList: [],
      mainIngredientList: [],
      notMainIngredientList: [],
      recipeGroupList: [],
      companyList: [],
      peopleGroupList: [],
      packageDetails: null,
      listGroup: [],
      dayList: [
        { id: 6, name: this.$t("Saturday") },
        { id: 0, name: this.$t("Sunday") },
        { id: 1, name: this.$t("Monday") },
        { id: 2, name: this.$t("Tuesday") },
        { id: 3, name: this.$t("Wedensday") },
        { id: 4, name: this.$t("Thursday") },
      ],
    };
  },
  methods: {
    changeRemindDays(val) {
      if(val == false) {
        this.formData.subscribe.count_of_remind_day= this.formData.subscribe.remind_days;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    // changeRenewSubscribe(val) {
    //   if (val == 2) {
    //     this.formData.subscribe.days = this.defaultDays;
    //     this.formData.subscribe.renew_date = null;
    //     this.formData.subscribe.renew_day_numbers = null;
    //   }
    //   else {
    //     this.formData.subscribe.renew_date = new Date();
    //     this.formData.subscribe.renew_day_numbers = this.formData.subscribe.remind_days;
    //   }
    // },
    dayListChanged() {
      // this.formData.subscribe.renewSubscribe = 1;
      // this.formData.subscribe.renew_date = new Date();
      // this.formData.subscribe.renew_day_numbers = this.formData.subscribe.remind_days;
    },
    removeRow(index) {
      this.formData.subscribe.group_subscribe.splice(index, 1);
    },
    addNew() {
      let listObj = {
        id: null,
        quantity: null,
      };
      this.formData.subscribe.group_subscribe.push(listObj);
    },
    next() {
      this.activeTab += 1;
    },
    changeDiscount() {
      if (
        this.packageDetails &&
        this.packageDetails.price &&
        this.formData &&
        this.formData.subscribe &&
        this.formData.subscribe.discount
      ) {
        let total =
          parseInt(this.packageDetails.price) -
          parseInt(this.formData.subscribe.discount);
        console.log(total);
        this.formData.subscribe.total = total;
      } else {
        this.formData.subscribe.total = this.packageDetails.price;
      }
    },
    getNeighborhoodList(id) {
      this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
        this.neighborhoodList = res.data;
      });
    },
    changePackageInitalize(id) {
      this.$store.dispatch(`subscribe/packageDetails`, id).then((res) => {
        this.packageDetails = res.data;
      });
    },
    changePackage(id) {
      this.$store.dispatch(`subscribe/packageDetails`, id).then((res) => {
        this.packageDetails = res.data;
        this.formData.subscribe.days = res.data.days;
        this.formData.subscribe.group_subscribe = res.data.details;

        
        console.log(res.data)
        this.formData.subscribe.number_of_days = res.data.number_of_days;


      });
    },
    save() {
      this.loadClicked= true;
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
          this.loadClicked= false;
          return this.notifyCustomError("Error", "ValidationsErrors")
        }

        let sendData = JSON.parse(JSON.stringify(this.formData));
        if (sendData.bank.transfer_date)
          sendData.bank.transfer_date = moment(
            sendData.bank.transfer_date
          ).format("YYYY-MM-DD");
        if (sendData.customer.birth_date)
          sendData.customer.birth_date = moment(
            sendData.customer.birth_date
          ).format("YYYY-MM-DD");
        if (sendData.personal_desires.start_date)
          sendData.personal_desires.start_date = moment(
            sendData.personal_desires.start_date
          ).format("YYYY-MM-DD");
        if (sendData.subscribe.start_date)
          sendData.subscribe.start_date = moment(
            sendData.subscribe.start_date
          ).format("YYYY-MM-DD");

        // if (sendData.subscribe.renew_date)
        //   sendData.subscribe.renew_date = moment(
        //     sendData.subscribe.renew_date
        //   ).format("YYYY-MM-DD");


        if (this.$route.params.id) {
          //edit
          sendData.id = this.$route.params.id;
          this.$store.dispatch(`subscribe/updateData`, sendData).then((res) => {
            this.notifySuccess("Update", res);
             this.loadClicked= false;
            this.$router.push({ name: "subscribe" });
          })
        } else {

          // delete sendData.subscribe.renew_date;
          
          this.$store.dispatch(`subscribe/saveData`, sendData).then((res) => {
            this.notifySuccess("Save", res);
             this.loadClicked= false;
            this.$router.push({ name: "subscribe" });
          }).finally(() => {
             this.loadClicked= false;
          })
        }
      });
    },
  },
  created() {

    let check_permessions= false;
    if(this.$route.params.id) { // edit


      

     if (
      (
        this.user &&
        this.user.permissions &&
        this.$route.params.id
         &&
        !this.user.permissions.includes("update_subscribe")  &&
         !this.user.permissions.includes("button_edit_subscribe")
        )
    ){

      this.$router.push({name: 'subscribe'});

      this.notifyCustomError("Error", "لا يوجد لديك صلاحية");

    }

    }
 
    else {  // add


        if (
      (this.user &&
        this.user.permissions &&
        !(this.user.permissions.includes("store_subscribe"))
      )
    ){

      this.$router.push({name: 'subscribe'});

      this.notifyCustomError("Error", "لا يوجد لديك صلاحية");

    }



    }

    check_permessions= true;


    if(check_permessions == true) {


      
    this.$store.dispatch(`subscribe/listBank`).then((res) => {
      this.bankList = res.data;
    });
    this.$store.dispatch(`subscribe/mainIngredientList`).then((res) => {
      this.mainIngredientList = res.data;
    });
    this.$store.dispatch(`subscribe/notMainIngredientList`).then((res) => {
      this.notMainIngredientList = res.data;
    });

    this.$store.dispatch(`subscribe/recipeGroupList`).then((res) => {
      this.recipeGroupList = res.data;
    });

    this.$store.dispatch(`subscribe/cityList`).then((res) => {
      this.cityList = res.data;
    });

    this.$store.dispatch(`subscribe/companyList`).then((res) => {
      this.companyList = res.data;
    });

    this.$store.dispatch(`subscribe/groupNameList`).then((res) => {
      this.peopleGroupList = res.data;
    });

    this.$store.dispatch(`subscribe/packageList`).then((res) => {
      this.packageList = res.data;
    });

    this.$store.dispatch(`package/listGroup`).then((res) => {
      this.listGroup = res.data;
    });

    if (this.$route.params.id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$store
        .dispatch(`subscribe/findData`, this.$route.params.id)
        .then((res) => {
          this.getNeighborhoodList(res.data.delivery.city_id);
          this.changePackageInitalize(res.data.subscribe.package_id);
          res.data.subscribe.day_status = 1;
          // res.data.subscribe.renewSubscribe = 2;
          // res.data.subscribe.renew_date = null;
          // res.data.subscribe.renew_day_numbers = null; 
          let newData = JSON.parse(JSON.stringify(res.data));
          this.defaultDays = newData.subscribe.days;
          res.data.subscribe.count_of_remind_day= res.data.subscribe.remind_days;
          this.formData = res.data;
            loading.close();
        });
    }



    }
  },
};
</script>



<style>
#pacakgeFeaturesTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pacakgeFeaturesTable td,
#pacakgeFeaturesTable th {
  border: 1px solid #ccc !important;
  padding: 8px;
}

#pacakgeFeaturesTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #04aa6d;
  color: white;
}
</style>